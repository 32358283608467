<template>
  <v-app>
    <v-container>
      <!--  -->
      <v-row justify="center">
        <v-col
          cols="12"
          md="8"
        >
          <!--  -->
          <base-material-card
            title="Edit Kategori"
            icon="mdi-calendar-check"
          >
            <!--  -->
            <v-card>
              <validation-observer>
                <v-form>
                  <!--  -->
                  <validation-provider
                    v-slot="{ errors }"
                    name="Name"
                    rules="required"
                  >
                    <v-text-field
                      v-model="show.data.id"
                      placeholder="Masukan Nama"
                      :error-messages="errors"
                      label="ID"
                      disabled
                      type="text"
                    />
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Name"
                    rules="required"
                  >
                    <v-text-field
                      v-model="show.data.name"
                      placeholder="Masukan Nama"
                      :error-messages="errors"
                      label="ID"
                      type="text"
                    />
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Type"
                    rules="required|"
                  >
                    <v-select
                      v-model="show.data.type"
                      :items="type_items"
                      :error-messages="errors"
                      label="Type"
                      required
                      item-text="name"
                      item-value="id"
                    />
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Status"
                    rules="required|"
                  >
                    <v-select
                      v-model="show.data.status"
                      :items="status_items"
                      :error-messages="errors"
                      label="Status"
                      required
                      item-text="name"
                      item-value="id"
                    />
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Komunitas"
                    rules="required|"
                  >
                    <v-select
                      v-model="show.data.community_id"
                      :items="komunitas.data"
                      :error-messages="errors"
                      label="Komunitas"
                      required
                      item-text="name"
                      item-value="id"
                    />
                  </validation-provider>
                  <div class="d-flex justify-end mt-6 mb-5 mr-6">
                    <v-btn
                      color="primary"
                      @click="editCategory"
                    >
                      sunting
                    </v-btn>
                  </div>
                </v-form>
              </validation-observer>
            </v-card>
          </base-material-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = 'https://nc.notiva.net'
  axios.defaults.headers.common.Authorization =
    'Bearer ' + localStorage.getItem('access_token')
  export default {
    name: 'ShowEvent',
    data: () => ({
      show: {
        meta: {},
        data: {
          community: {
            name: '',
          },
        },
      },
      komunitas: {},
      type_items: ['event', 'news'],
      status_items: ['draft', 'publish', 'review'],
    }),
    mounted () {
      this.getNews()
      this.getKomunitas()
    },
    methods: {
      setKomunitas (data) {
        this.komunitas = data
      },
      getKomunitas () {
        axios.get('/v1/community/?sort=-id&offset=999').then(response => {
          this.setKomunitas(response.data)
        })
      },
      editCategory () {
        axios
          .patch('/v1/category/' + this.$route.params.id, {
            name: this.show.data.name,
            type: this.show.data.type,
            status: this.show.data.status,
            community_id: this.show.data.community_id,
            parent_id: '2',
          })
          .then(response => {
            if (response.data.meta.status) {
              this.$router.push({
                path: '/kategori/' + this.show.data.type,
              })
              this.$toast.success('Berhasil Di Buat', {
                type: 'success',
                position: 'top-right',
                duration: 3000,
                dismissible: true,
              })
            }
          })
      },
      setNews (data) {
        this.show = data
      },
      getNews () {
        axios
          .get('/v1/category/' + this.$route.params.id + '?entities=community')
          .then(response => {
            console.log(response.data)
            this.setNews(response.data)
          })
      },
    },
  }
</script>

<style></style>
